$green: #00D190;
$orange: #FDA50F;
$white:  #FFFFFF;
$black:  #232323;
$d-grey: #747474;
$white:  #FFFFFF;

@mixin transition($time) {
  transition: all $time ease; }

body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  color: #747474;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 16px; } }
@media (min-width: 1400px) {
  .container {
    max-width: 1335px; } }


.p-bolder {
  font-weight: 900; }
.p-bold {
  font-weight: 700; }
.p-bold2, b {
  font-weight: 500; }

.p-big {
  font-size: 72px;
  line-height: 1.2;
  @media (max-width: 1024px) {
    font-size: 60px; }
  @media (max-width: 768px) {
    font-size: 50px; } }
.h1 {
  font-size: 48px;
  line-height: 56px; }
.p-max {
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 20px; } }
.p-max-med {
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 18px; } }
.p-min {
  font-size: 14px; }

.p_white {
  color: $white; }
.p_white {
  color: $white; }
.p_green {
  color: $green; }
.p_orange {
  color: $orange; }
.p_black {
  color: $black; }
.p_d-grey {
  color: $d-grey; }
.p-center {
  text-align: center; }
a {
  color: $white;
  text-decoration: underline;
  @include transition(0.3s);
  &:hover {
    color: $white;
    opacity: 0.9; } }

button:focus {
  outline: none; }
.alert-success {}





