#main {
  position: relative;
  z-index: 4;
  background-color: #ffffff;
  .start-section {
    background-image: url('/images/BG.png');
    background-size: cover;
    background-position: left bottom;
    //height: 100vh
    padding: 40px 0;
    max-height: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0 0 0 25vh;
    @media (max-width: 768px) {
      //height: auto
      padding: 80px 0;
      border-radius: 0 0 0 200px; }
    @media (max-width: 425px) {
      padding: 40px 0;
      border-radius: 0 0 0 100px; }
    .logo {
      @media (max-width: 425px) {
        display: none; } }
    .logo.mobile {
      display: block;
      max-width: 80%;
      margin: 0 auto 20px auto;
      @media (min-width: 426px) {
        display: none; }
      img {
        max-width: 100%; } }

    .img {
      position: relative;
      @media (max-width: 425px) {
        max-width: 71%;
        margin: 0 auto 40px auto; }
      .img__1 {
        max-width: 60%;
        @media (max-width: 425px) {
          max-width: 65%; }
        img {
          max-width: 100%;
          height: auto; } }
      .img__2 {
        max-width: 40%;
        position: absolute;
        top: 25%;
        left: 50%;
        @media (max-width: 425px) {
          max-width: 45%;
          left: 60%; }
        img {
          max-width: 100%;
          height: auto; } } }
    .title {
      max-width: 100%;
      .logo {
        margin-bottom: 60px;
        img {
          width: 100%;
          max-width: 542px; } }
      .store {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media (max-width: 768px) {
          flex-direction: column; }
        @media (max-width: 768px) {
          flex-direction: row;
          justify-content: space-between; }
        a {
          text-decoration: none;
          padding: 0 13px;
          flex: 0 0 46%;
          max-width: 46%;
          img {
            max-width: 100%; }
          @media (max-width: 768px) {
            padding: 0;
            text-align: right;
            margin-bottom: 20px; } } } } }
  .what-section {
    padding-top: 160px;
    @media (max-width: 1024px) {
      padding-top: 100px; }
    @media (max-width: 425px) {
      padding-top: 60px; }
    .title {
      margin-bottom: 40px;
      @media (max-width: 1024px) {
        margin-bottom: 30px; } }
    .title-step {
      margin-bottom: 33px;
      @media (max-width: 768px) {
        margin-top: 40px; } }
    .step-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .step-item {
        flex: 0 0 auto;
        text-align: center;
        margin-bottom: 50px;
        @media (max-width: 1024px) {
          margin-bottom: 30px; }
        @media (max-width: 991px) {
          flex: 0 0 50%;
          text-align: center; }
        @media (max-width: 425px) {
          flex: 0 0 45%;
          max-width: 45%;
          img {
            max-width: 100%; } }

        img {
          margin-bottom: 20px;
 } } } }          //BG_orange.png
  .journal-section {
    margin-top: -100px;
    @media (max-width: 768px) {
      margin-top: -20px; }
    .background_1 {
      border-radius: 0 0 0 300px;
      @media (max-width: 1024px) {
        border-radius: 0 0 0 200px; }
      @media (max-width: 425px) {
        border-radius: 0 0 0 150px; }
      overflow: hidden;
      .background_2 {
        background-image: url("/images/square_BG.png");
        background-size: cover;
        background-position: center;
        border-radius: 0 0 200px 0;
        overflow: hidden;

        @media (max-width: 1024px) {
          border-radius: 0 0 150px 0; }
        @media (max-width: 768px) {
          background-image: url("/images/square_BG_mini.png"); }
        @media (max-width: 425px) {
          border-radius: 0 0 0 100px; }
        .background_3 {
          margin: 0 auto;
          max-width: 1920px;
          background-image: url("/images/curve.png");
          background-size: contain;
          background-position: center top;
          background-repeat: no-repeat;
          padding-top: 200px;
          padding-bottom: 120px;
          @media (min-width: 1921px) {
            margin-top: -100px; }
          @media (max-width: 425px) {
            padding-top: 160px;
            padding-bottom: 80px; }
          .img {
            img {
              width: 600px;
              max-width: 100%; } } } } } }
  .resource-section {
    padding-top: 160px;
    padding-bottom: 160px;
    @media (max-width: 1024px) {
      padding-top: 100px;
      padding-bottom: 100px; }
    .title {
      margin-bottom: 60px;
      @media (max-width: 1024px) {
        margin-bottom: 40px; } }
    .resource-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media (max-width: 1024px) {
        justify-content: space-between;
        flex-wrap: wrap; }
      .res-link {
        margin: 0 60px 20px 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          margin-right: 10px; }
        @media (max-width: 1400px) {
          flex-direction: column;
          justify-content: center;
          span {
            display: block;
            text-align: center; }
          img {
            display: block;
            margin-bottom: 10px; } }

        @media (max-width: 1024px) {
          margin: 0 0 20px 0; }
        @media (max-width: 767px) {
          flex-direction: row;
          justify-content: flex-start;
          flex: 0 0 100%; } } } }


  .contact-section {
    .background_1 {
      border-radius: 0 0 0 500px;
      background-color: $white;
      .background_2 {
        border-radius: 0 300px 0 300px;
        background-image: url('/images/BG.png');
        background-size: cover;
        background-position: left bottom;
        overflow: hidden;
        padding-top: 108px;
        padding-bottom: 133px;
        @media (max-width: 1024px) {
          border-radius: 0 200px 0 150px;
          padding-top: 80px;
          padding-bottom: 100px; }
        .form {
          background: #FFFFFF;
          border-radius: 20px;
          padding: 40px;
          .form-group {
            margin-bottom: 30px;
            label {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              display: block;
              margin-bottom: 4px; }
            .form-control {
              width: 100%;
              display: block;
              background: $white;
              border: 2px solid $orange;
              border-radius: 8px;
              color: $d-grey;
              font-size: 18px;
              padding: 14px 12px;
              line-height: 21px;
              @include transition(0.3s);
              &::placeholder {
                color: #D7D7D7; }
              &:focus {
                border: 2px solid #00D190;
                background: rgba(0, 209, 144, 0.1);
                outline: none; } }
            label.error {
              color: #ff5252;
              font-size: 14px;
              margin-bottom: -19px; }
            .alert-danger {
              font-size: 14px;
              border: 2px solid #ff5252;
              background: rgba(255, 82, 82, 0.1);
              padding: 14px 12px;
              border-radius: 8px;
              color: #ff5252; }
            .alert-success {
              font-size: 14px;
              border: 2px solid #00d190;
              background: rgba(0,209,144,.1);
              padding: 14px 12px;
              border-radius: 8px;
              color: #00d190; }
            &.checkbox {
              label {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                &.error {
                  color: #ff5252;
                  font-size: 14px;
                  margin-bottom: -19px;
                  font-weight: 500;
                  line-height: 19px;
                  display: block; } }
              .check {

                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                flex: 0 0 24px;
                margin-right: 11px;
                span {
                  border-style: solid;
                  border-width: 2px;
                  border-color: #FDA50F;
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  z-index: 2; }

                input {
                  display: block;
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  top: 0;
                  left: 0;
                  z-index: 3;
                  opacity: 0;
                  &:checked + span {
                    background-image: url("/images/check.svg");
                    background-size: 18px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border-color: #00D190;
                    background-color: rgba(0, 209, 144, 0.1); } } } }
            &.btn-group {
              text-align: center;
              .btn-form {
                border: 3px solid $green;
                box-shadow: 0px 0px 10px rgba(0, 209, 144, 0.4);
                border-radius: 10px;
                color: $green;
                font-weight: bold;
                text-transform: uppercase;
                background: $white;
                padding: 15px 50px;
                @include transition(0.3s);
                &:hover {
                  border: 3px solid $orange;
                  color: $orange; } } } }

          .privacy {
            p {
              font-size: 12px;
              line-height: 14px;
              a {
                color: #4388F2;
                &:hover {
                  color: #4388F2;
                  opacity: 0.9; } } } } } } } }
  .content_page {
    padding-top: 50px;
    @media (max-width: 425px) {
      padding-top: 30px; }
    .title {
      margin-bottom: 40px; }
    .main-content {
      padding-bottom: 110px;
      @media (max-width: 425px) {
        padding-bottom: 60px; }
      p {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 150%; }
      .respect {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin-bottom: 0;
          margin-left: 10px; } }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        li {
          font-size: 16px;
          line-height: 150%; } } } } }


































