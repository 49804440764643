.header {
  padding-top: 70px;
  padding-bottom: 20px;
  @media (max-width: 425px) {
    padding-top: 40px; } }
#header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 425px) {
    flex-wrap: wrap; }
  .back-btn {
    position: absolute;
    top: 20px;
    left: 0;
    @media (max-width: 425px) {
      position: static;
      flex: 0 0 100%;
      margin-top: 20px; }
    .back {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        margin-bottom: 0;
        margin-left: 10px;
        color: #747474; } } } }



